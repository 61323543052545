
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import ScoreScaleVue from '@/components/score-scale.vue'
import ScoreDialog from './component/scoreDialog.vue'
import { ElLoading } from 'element-plus'
import myAppointedExpert from './appointed-expert/appointed-expert.vue'
import { httpRequest } from '@/plugins/index'
type ScoreRoleTypes = [number, number, number[], string | number]

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    ScoreScaleVue,
    ScoreDialog,
    myAppointedExpert
  }
})
export default class patent_task extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  audit_dialog = {
    open: false,
    title: '审核意见',
    row: {} as any,
    reason: '',
    status: 6
  }
  auditConfirm() {
    if (this.audit_dialog.status === 5 && !this.audit_dialog.reason) {
      return this.$message.error('请输入退回理由')
    }
    httpRequest
      .post('api/v1/patent_task/t/editAudit', {
        opinion_id: this.audit_dialog.row.id,
        status: this.audit_dialog.status,
        reason: this.audit_dialog.reason
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.t_edit2_data.opinionList[this.opinionListIndex].status
            = this.audit_dialog.status
          this.$message.success(res.message)
          this.audit_dialog.open = false
          this.t_get_all_data(
            (this.tb.page - 1) * this.tb.pageSize,
            this.tb.pageSize
          )
        } else this.$message.error(res.message)
      })
  }

  appeal_dialog = {
    open: false,
    title: '申请申诉',
    report_return_reason: '',
    return_reason: '',
    status: '',
    id: -1
  }
  appeal_dialog_rule = {
    status: [{ required: true, message: '请填写完整', trigger: 'blur' }],
    return_reason: [{ required: true, message: '请填写完整', trigger: 'blur' }]
  }
  appeal_dialog_init(row) {
    this.appeal_dialog.return_reason = ''
    this.appeal_dialog.id = row.id
    this.appeal_dialog.status = ''
    httpRequest
      .post('api/v1/my_patent/t/appealsDetail', {
        id: this.appeal_dialog.id
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.appeal_dialog.report_return_reason
            = res.data.report_return_reason
          this.appeal_dialog.open = true
        }
      })
  }
  appeal_dialog_ok() {
    this.$refs.ref_appeal_dialog.validate((valid) => {
      if (valid) {
        httpRequest
          .post('api/v1/my_patent/t/auditAppeals', {
            patent_id: this.appeal_dialog.id,
            return_reason: this.appeal_dialog.return_reason,
            status: this.appeal_dialog.status
          })
          .then((res: any) => {
            if (res.code === '200') {
              this.$message.success('提交申述成功')
              this.t_get_all_data(
                (this.tb.page - 1) * this.tb.pageSize,
                this.tb.pageSize
              )
              this.appeal_dialog.open = false
            }
          })
      }
    })
  }

  // 评分标准弹窗逻辑-start
  scoreScaleData = {
    open: false,
    evaluation_criterion: '',
    weightItems: []
  }
  get_evaluation_criterion(id: number): void {
    Api.http_get_evaluation_criterion({ id: id }).then((res) => {
      if (res.code === '200') {
        this.scoreScaleData.evaluation_criterion = res.data.evaluation_criterion
        this.scoreScaleData.weightItems = res.data.weightItems
        this.scoreScaleData.open = true
      }
    })
  }
  // end
  // 专家评分服务弹窗逻辑-start
  scoreDialogData = {
    open: false,
    evaluateData: {}
  }
  editEvaluate(data) {
    // 提交组长对某专家的点评数据
    // if (data.status !== 5 && data.status !== 6) {
    //   this.$message.warning('请选择采纳状态!')
    //   return
    // }
    if (data.content === '') {
      this.$message.warning('请填写点评内容!')
      return
    }
    const postData = {
      evaluate_id: data.evaluate_id,
      attitude_score: data.attitude_score,
      content: data.content,
      expert_id: data.expert_id,
      id: data.id,
      quality_score: data.quality_score,
      status: data.status
    }
    Api.http_editEvaluate(postData).then((res) => {
      if (res.code === '200') {
        this.$message.success(res.message)
        this.scoreDialogData.open = false
      } else this.$message.warning(res.message)
    })
  }
  openScoreDialog(item: any) {
    if (item.status < 4) {
      this.$message.warning('专家尚未完成评估!')
      return
    }
    this.scoreDialogData.evaluateData = item
    this.scoreDialogData.open = true
  }
  // end

  opinionListIndex: number = 0
  status2chinese(status: number): string {
    if (status === 0) return '待确认邀请'
    if (status === 1) return '拒绝邀请'
    if (status === 2) return '待评估'
    if (status === 3) return '草稿'
    if (status === 4) return '待审核'
    if (status === 5) return '已退回'
    if (status === 6) return '已通过'
    return '已废弃'
  }

  // 搜索专家
  outPut(name) {
    console.log('name', name)
    this.get_expertList(
      this.t_edit1_data.id,
      [0, 5, 8, 10][this.t_edit1_data.result_type],
      name
    )
  }

  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }

  t_all_data = []

  t_total = 0
  t_search_data = {
    name: '',
    start_time: '',
    end_time: '',
    status: '',
    user_name: '',
    order_by: '',
    sort: ''
  }

  rule_t_search_data = {}

  t_edit_dialog = {
    open: false,
    title: '评估结论',
    type: 'edit'
  }

  t_edit_data = {
    id: 0,
    status_: 0 as number | string,
    total_score: 0,
    patent_level: '',
    conclusion: '',
    status: 0,
    report_return_reason: '' // 报告退回原因
  }

  rule_t_edit_data = {}

  t_edit1_dialog = {
    open: false,
    title: '邀请评估',
    type: 1 // 1,邀请专家;2,指派评估员
  }

  t_edit1_data = {
    id: 0,
    name: '',
    result_type: 0,
    user_list: [] as any[]
  }

  rule_t_edit1_data = {}

  t_edit2_dialog = {
    open: false,
    title: '点评专家意见'
  }

  t_edit2_data = {
    id: 0,
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: '',
      industry_involved: '',
      related_industry: '',
      patentee: '',
      digest: '',
      claim_rights: '',
      specification: '',
      result_form: '',
      others_msg: [] as any,
      digest_file: [] as any,
      claim_rights_file: [] as any,
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: '',
      max_price: 0,
      min_price: 0
    },
    self_info: [],
    opinionList: [] as any[]
  }

  rule_t_edit2_data = {}

  t_edit3_dialog = {
    open: false,
    title: '评估规则'
  }

  t_edit3_data = {
    id: 0,
    score_rule: [-1, -1, [], 0] as ScoreRoleTypes
  }

  rule_t_edit3_data = {}

  expertList: any[] = []
  score_rule: any[] = []
  // 0--草稿，1--待发明人确认，2--待指派组长，3--待分配专家，4---待专家评估，5--待组长审核，6--审核通过，7--审核不通过
  status_816 = new Map<any, string>([
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过'],
    [8, '待审核申诉'],
    [9, '成员退回'],
    [10, '申诉审核不通过'],
    [11, '申诉审核通过']
  ])
  status__823 = new Map<any, string>([
    [6, '通过'],
    [7, '不通过']
  ])
  status_871 = new Map<any, string>([
    [6, '采纳'],
    [5, '退回']
  ])
  type_100 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])

  t_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.t_search_data.name,
      start_time: this.t_search_data.start_time,
      end_time: this.t_search_data.end_time,
      status: this.t_search_data.status,
      user_name: this.t_search_data.user_name,
      patent_type: 1,
      order_by: this.t_search_data.order_by,
      sort: this.t_search_data.sort
    }
    Api.http_patent_taskt0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        console.log('d', res)
        this.t_all_data = res.data.data
        this.t_total = res.data.total
      }
    })
  }
  loading
  t_edit_init(row, type) {
    this.t_edit_dialog.type = type
    if (row.assess_score === null) {
      this.$message.warning('请先完成评估规则设定!')
      return
    }
    this.loading = ElLoading.service({
      lock: true,
      text: '加载中',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.getWeights(row)
    // Api.http_patent_tasktget5({ id: row.id }).then((res) => {
    //   if (res.code !== '200') {
    //     this.$message.error(res.message)
    //   } else {
    //     this.t_edit_data.id = row.id
    //     this.t_edit_data.status_ = ''
    //     this.t_edit_dialog.open = true
    //   }
    // })
  }
  WeightList = [] as any[]
  finishData = [] as any[]

  getWeights(row) {
    Api.http_my_patenttWeightList0({
      id: -1,
      offset: 0,
      length: 999,
      type_id: [0, 5, 6, 10][row.result_type]
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        // console.log('55555555555', res)
        this.WeightList = res.data.data
        Api.http_patent_tasktget5({ id: row.id }).then((res1) => {
          if (res1.code !== '200') {
            this.$message.error(res1.message)
          } else {
            this.t_edit_data.id = row.id
            this.t_edit_data.total_score = 0
            this.finishData = res1.data.opinionsList
            this.finishData.forEach((item) => {
              item.total_score = 0
              item._total_score = 0
              item.opinion_items.forEach((it) => {
                item[`parent_${ it.weight_id }`] = it.score
                item.total_score += it.score
                item._total_score += it.score * (it.weight / 10)
              })
              this.t_edit_data.total_score += item._total_score
            })
            this.t_edit_data.total_score = Number(
              this.t_edit_data.total_score.toFixed(2)
            )
            this.t_edit_data.patent_level = Utils.getPatentLevel(
              this.t_edit_data.total_score
            )
            this.t_edit_data.status_ = 6
            this.t_edit_data.status = res1.data.status_ // 专利当前状态
            this.t_edit_data.conclusion = res1.data.conclusion
            this.t_edit_data.report_return_reason
              = res1.data.report_return_reason
            this.t_edit_dialog.open = true
            this.loading.close()
          }
        })
      }
    })
  }
  t_edit_cancle() {
    this.t_edit_dialog.open = false
  }
  t_edit_ok() {
    if (this.t_edit_data.status_ === '') {
      this.$message.warning('请选择一项!')
      return
    }
    if (!this.t_edit_data.conclusion) {
      return this.$message.error('评估结论不能为空')
    }
    const postData = {
      id: this.t_edit_data.id,
      status_: this.t_edit_data.status_,
      evaluation_score: this.t_edit_data.total_score,
      patent_level: this.t_edit_data.patent_level,
      conclusion: this.t_edit_data.conclusion
    }
    Api.http_patent_taskt5(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_dialog.open = false
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  t_edit1_init(row) {
    this.get_expertList(row.id, [0, 5, 8, 10][row.result_type])
    Api.http_patent_tasktget6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit1_data.id = row.id
        this.t_edit1_data.result_type = row.result_type //
        this.t_edit1_data.name = res.data.name
        this.t_edit1_data.user_list = res.data.user_list
        this.t_edit1_dialog.open = true
      }
    })
  }
  // 切换评估邀请邀请人员类型
  t_edit_change_title(type) {
    this.t_edit1_dialog.type = type
    this.get_expertList(
      this.t_edit1_data.id,
      [0, 5, 8, 10][this.t_edit1_data.result_type]
    )
  }
  t_edit1_cancle() {
    this.t_edit1_dialog.open = false
  }
  t_edit1_ok() {
    this.$refs.ref_t_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.t_edit1_data.id,
          name: this.t_edit1_data.name,
          user_list: [] as any[],
          ids: this.$refs.appointed.delIds
        }
        this.t_edit1_data.user_list.forEach((item) => {
          if (!item.disabled) postData.user_list.push(item)
        })
        Api.http_patent_taskt6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit1_dialog.open = false
            this.t_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  t_edit2_init(row) {
    Api.http_patent_tasktget7({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit2_data.id = row.id
        this.t_edit2_data.person_info = res.data.person_info
        this.t_edit2_data.base_info = res.data.base_info
        this.t_edit2_data.supplement = res.data.supplement
        this.t_edit2_data.conversion_situation = res.data.conversion_situation
        if (this.t_edit2_data.conversion_situation.expect) {
          this.t_edit2_data.conversion_situation.expect = JSON.parse(
            this.t_edit2_data.conversion_situation.expect
          ).join(',')
        }
        if (Utils.isJSON(this.t_edit2_data.base_info.others_msg)) {
          this.t_edit2_data.base_info.others_msg = JSON.parse(
            this.t_edit2_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.t_edit2_data.base_info.claim_rights_file)) {
          this.t_edit2_data.base_info.claim_rights_file = JSON.parse(
            this.t_edit2_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.t_edit2_data.base_info.digest_file)) {
          this.t_edit2_data.base_info.digest_file = JSON.parse(
            this.t_edit2_data.base_info.digest_file
          )
        }
        if (Utils.isJSON(this.t_edit2_data.base_info.specification_file)) {
          this.t_edit2_data.base_info.specification_file = JSON.parse(
            this.t_edit2_data.base_info.specification_file
          )
        }
        this.t_edit2_data.self_info = res.data.self_info
        this.t_edit2_data.opinionList = res.data.opinionList
        this.t_edit2_dialog.open = true
        this.setDialogName(this.t_edit2_dialog.title)
      }
    })
  }
  t_edit2_cancle() {
    this.t_edit2_dialog.open = false
    this.removeDialogName()
  }
  t_edit2_ok() {
    this.audit_dialog.row = this.t_edit2_data.opinionList[this.opinionListIndex]
    this.audit_dialog.open = true
    // this.$refs.ref_t_edit2_data.validate(valid => {
    //   if (valid) {
    //     const postData = {
    //       id: this.t_edit2_data.id,
    //       opinionList: this.t_edit2_data.opinionList
    //     }
    //     Api.http_patent_taskt7(postData).then(res => {
    //       if (res.code !== '200') {
    //         this.$message.error(res.message)
    //       } else {
    //         this.t_edit2_dialog.open = false
    //         this.t_get_all_data(0, 10)
    //         this.$message.success(JSON.stringify(res.message))
    //       }
    //     })
    //   }
    // })
    // this.t_edit2_dialog.open = false
    // this.removeDialogName()
  }
  t_edit3_init(row) {
    this.get_score_rule(row.id)
    Api.http_patent_tasktget8({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit3_data.id = row.id
        this.t_edit3_data.score_rule = res.data.score_rule
        this.t_edit3_dialog.open = true
      }
    })
  }
  t_edit3_cancle() {
    this.t_edit3_dialog.open = false
  }
  // 添加评估规则
  t_edit3_ok() {
    if (this.t_edit3_data.score_rule[0] < 0) {
      this.$message.warning('请至少选择一项评分规则!')
      return
    }
    if (
      this.t_edit3_data.score_rule[0] === 2
      && this.t_edit3_data.score_rule[1] < 0
    ) {
      this.$message.warning('请至少选择一个专家!')
      return
    }
    if (
      this.t_edit3_data.score_rule[0] === 3
      && this.t_edit3_data.score_rule[2].length === 0
    ) {
      this.$message.warning('请至少选择一个专家!')
      return
    }
    if (
      this.t_edit3_data.score_rule[0] === 4
      && this.t_edit3_data.score_rule[3] === ''
    ) {
      this.$message.warning('请填写分数!')
      return
    }
    const postData = {
      id: this.t_edit3_data.id,
      score_rule: this.t_edit3_data.score_rule
    }
    Api.http_patent_taskt8(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit3_dialog.open = false
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }

  get_expertList(id = -1, type_id, keyword = '') {
    const postData = {
      id: id,
      offset: 0,
      length: 999,
      role_id: 3,
      type_id,
      keyword,
      type: this.t_edit1_dialog.type
    }
    postData.role_id = [-1, 3, 74][this.t_edit1_dialog.type]
    Api.http_patent_tasktexpertList0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        console.log('f', res)
        this.expertList = res.data.data
      }
    })
  }

  get_score_rule(id = -1) {
    Api.http_patent_tasktscore_rule0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.score_rule = res.data.data
        }
      }
    )
  }

  created() {
    this.t_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
}
