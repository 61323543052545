
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
import Config from '@/config'

@Options({
  components: {},
  props: {
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    options: {
      type: Array,
      default() {
        return [{ users: [], info: [], weights: [] }]
      }
    },
    memberType: {
      type: Number,
      default: 1
    }, // 1 咨询专家 2 评估员
    type: { // true 专利水平，false 专利申请前
      type: Boolean,
      default: false
    }
  },
  watch: {
    options(val) {
      // console.log('type', this.type)
      this.options[0].users.forEach((item) => {
        const index = this.modelValue.findIndex((itemx) => itemx.id === item.id)
        if (index > -1) item.selected = true
        else item.selected = false
      })
      this.resetRightPane()
      if (this.type) this.setTreeData(val)
    }
  }
})
export default class AppointedExpert extends SuperVue {
  // props
  modelValue!: any[]
  options!: any[]
  type!: boolean
  memberType!:any[]

  // data
  msgCheckList = []
  weightCheckList = []
  currentUser = -1
  keyword = ''
  delIds = [] as number[] // 需要删除的ID
  cDidsbled = true
  assess_request = ''

  checkAll = false
  checkWeights = false
  isIndeterminate = true
  isIndeterminate_sec = true

  treeData = []

  handleCheckChange(v1, v2) {
    if (this.currentUser < 0) return
    this.modelValue[this.currentUser].weights.length = 0
    v2.checkedNodes.forEach((item) => {
      if (!Config.weightsTypes.find((it) => it.name === item.name)) {
        this.modelValue[this.currentUser].weights.push(item.id)
      }
    })
  }

  handleCheckAllChange(val) {
    if (this.currentUser < 0) {
      return
    }
    if (val) {
      this.modelValue[this.currentUser].info = this.options[0].info.map(
        (item) => item.id
      )
    } else {
      this.modelValue[this.currentUser].info = []
    }
    this.isIndeterminate = false
  }

  handleCheckedChange(value) {
    if (this.currentUser < 0) {
      return
    }
    if (this.modelValue[this.currentUser].info.length === 0) {
      this.isIndeterminate = false
      this.checkAll = false
    } else if (
      this.modelValue[this.currentUser].info.length
      === this.options[0].info.map((item) => item.id).length
    ) {
      this.isIndeterminate = false
      this.checkAll = true
    } else {
      this.isIndeterminate = true
      this.checkAll = false
    }
  }

  weightCheckAllChange(val) {
    if (this.currentUser < 0) {
      return
    }
    if (val) {
      this.modelValue[this.currentUser].weights = this.options[0].weights.map(
        (item) => item.id
      )
    } else {
      this.modelValue[this.currentUser].weights = []
    }
    this.isIndeterminate_sec = false
  }

  weightCheckedChange(value) {
    if (this.currentUser < 0) {
      return
    }
    if (this.modelValue[this.currentUser].weights.length === 0) {
      this.isIndeterminate_sec = false
      this.checkWeights = false
    } else if (
      this.modelValue[this.currentUser].weights.length
      === this.options[0].weights.map((item) => item.id).length
    ) {
      this.isIndeterminate_sec = false
      this.checkWeights = true
    } else {
      this.isIndeterminate_sec = true
      this.checkWeights = false
    }
  }

  //
  parentSearch() {
    this.$emit('getName', this.keyword)
  }

  personSelectShow = true
  // 删除已选中列表项
  delSelected(row, index) {
    this.modelValue.splice(index, 1)
    this.options[0].users.forEach((item) => {
      if (item.id === row.id) item.selected = false
    })
    // 存储已在数据库但被删除的ID
    if (row.opinion_id > 0) this.delIds.push(row.opinion_id)
    // Utils.log('delIds', this.delIds)
    this.setCurrentUser()
    this.resetRightPane()

  }

  resetRightPane() {
    // 清空右侧面板
    this.personSelectShow = false
    this.currentUser = -1
    this.msgCheckList = []
    this.$nextTick(() => {
      this.personSelectShow = true
    })
  }

  // 点击选中用户
  selectUser(row, index) {
    this.currentUser = index
    this.msgCheckList = row.info
    this.weightCheckList = row.weights
    this.assess_request = row.assess_request
    console.log(row.status)
    // eslint-disable-next-line no-unneeded-ternary
    this.cDidsbled = row.status >= 3 ? true : false
    this.setCheckedKeys(this.modelValue[this.currentUser].weights)

    this.setTreeData(this.options) // 必须先设定cDidsbled

    // Utils.log('row', row)
    // Utils.log('msgCheckList', this.msgCheckList)
    // Utils.log('weightCheckList', this.weightCheckList)
  }

  // 计算当前已选人员选中项
  setCurrentUser() {
    if (this.modelValue.length - 1 <= this.currentUser) {
      this.currentUser = -1
    }
  }

  // 左侧表格双击事件
  selectTableUser(row) {
    // console.log('2222222222', row);
    const index = this.modelValue.findIndex((item) => item.id === row.id)
    if (index > -1) return
    this.modelValue.push({
      opinion_id: -1,
      dept: row.dept,
      id: row.id, // 用户ID
      name: row.name,
      phone: row.phone,
      info: [],
      weights: [],
      disabled: false,
      status: -1,
      assess_request: '',
      type: this.memberType
    })
  }

  setTreeData(val) {
    if (this.type) {
      const treeData: any = Config.weightsTypes.slice(1)
      // Utils.log('treeData', treeData)
      treeData.forEach((item) => {
        if (this.currentUser > -1 && this.modelValue[this.currentUser].disabled) { item.disabled = true } else item.disabled = false
        item.children = []
        val[0].weights.forEach((it) => {
          if (
            this.currentUser > -1
            && this.modelValue[this.currentUser].disabled
          ) { it.disabled = true } else it.disabled = false
          if (it.parent_id === item.id) item.children.push(it)
        })
      })
      this.treeData = treeData
      console.log('treeData :>> ', treeData)
    }
  }

  setCheckedKeys(weights = [] as any[]) {
    if (!this.type) return
    const keys = [] as any[]
    this.options[0].weights.forEach((item) => {
      weights.forEach((it) => {
        if (item.id === it) keys.push(item.name)
      })
    })
    // console.log('keys :>> ', keys);
    this.$refs['weights-tree'].setCheckedKeys(keys)
  }

  created() {
    if (this.options.length === 0) {
      this.options.push({ users: [], info: [], weights: [] })
    }
    // 禁止删除已评估专家
    if (this.modelValue.length > 0) {
      this.currentUser = 0
      this.cDidsbled = this.modelValue[this.currentUser].status > 2
    }
    this.modelValue.forEach((item) => {
      if (item.status >= 3) {
        item.disabled = true
      } else {
        item.disabled = false
      }
    })
    // 设置已选人员
    this.options[0].users.forEach((item) => {
      const index = this.modelValue.findIndex((itemx) => itemx.id === item.id)
      if (index > -1) item.selected = true
      else item.selected = false
    })
    this.setTreeData(this.options)
  }
  mounted() {
    this.currentUser > -1
      ? this.setCheckedKeys(this.modelValue[this.currentUser].weights)
      : ''
  }
}
