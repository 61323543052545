
import { defineComponent, ref, watchEffect, watch } from '@vue/runtime-core';
import { reactive, PropType } from 'vue';
type EvaluateData={
          attitude_score: number,
          quality_score: number,
          time_score: number,
          content: string,
          status: number|string,
          expert_id: number,
          id: number,
          evaluate_id:number
        }
export default defineComponent({
  name: 'ScoreDialog',
  props: {
    modelValue: Boolean,
    evaluateData: Object as PropType<EvaluateData>
  },
  emits: ['update:modelValue', 'ok'],
  setup(props, context) {
    const show = ref(props.modelValue)
    watchEffect(() => { context.emit('update:modelValue', show.value) })
    watchEffect(() => { show.value = props.modelValue })
    // watchEffect(() => {
    //   if (props.evaluateData !== undefined) { evaluate.value = props.evaluateData }
    // })
    // const evaluate = ref<EvaluateData>({
    //   attitude_score: 1,
    //   quality_score: 1,
    //   time_score: 1,
    //   content: '',
    //   status: '' as number|string,
    //   expert_id: 0,
    //   id: 0
    // })
    return { show }
  },
  data() {
    return {
      evaluate: {
        attitude_score: 1,
        quality_score: 1,
        time_score: 1,
        content: '',
        status: '' as number|string,
        expert_id: 0,
        id: 0
      } as any
    }
  },
  watch: {
    evaluateData() {
      console.log(this.evaluateData)
      this.evaluate = JSON.parse(JSON.stringify(this.evaluateData))
    }
  }
})
